.accordion-container {
	.accordion-head-section {
		display: flex;
		justify-content: space-between;
	}
	.accordion {
		margin: 24px 0 0px 0;
		cursor: pointer;
		transition: 0.4s;
	}
	.active {
		margin: 24px 0 8px 0;
	}
	.panel {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
		margin-bottom: 24px;
		.cookies-detail-container {
			@media #{'only screen and (max-width : 767px)'} {
				overflow-x: auto;
				width: 80vw;
			}
		}
		table {
			width: 100%;
			tr {
				th {
					border: 1px solid #d5d5d5;
					background: #f3f3f3;
					font-weight: 700;
					padding: 12px 16px;
				}
				td {
					border-right: 1px solid #d5d5d5;
					border-bottom: 1px solid #d5d5d5;
					border-left: 1px solid #d5d5d5;
					font-weight: 400;
					padding: 12px 16px;
				}
			}
		}
	}
}
