@use "sass:meta";
@use "./vars";

@mixin color_palette($args...) {
	@each $name, $color in meta.keywords($args) {
		&#{$name} {
			color: $color !important;
		}
		&#{$name}-bg {
			background-color: $color !important;
		}
		&#{$name}-bd {
			border-color: $color !important;
		}
	}
}
.white {
	color: $white;
}
.black {
	color: $black;
}
.grey {
	color: $grey--500 !important;
	@include color_palette(
		$--100: $grey--100,
		$--200: $grey--200,
		$--300: $grey--300,
		$--400: $grey--400,
		$--500: $grey--500,
		$--600: $grey--600,
		$--700: $grey--700,
		$--800: $grey--800,
		$--900: $grey--900,
		$--BG: $grey--BG
	);
}

.ruby-red {
	color: $ruby-red--800 !important;
	@include color_palette(
		$--700: $ruby-red--700,
		$--800: $ruby-red--800,
		$--900: $ruby-red--900
	);
}
.deepest-blue {
	color: $deepest-blue--800 !important;
	@include color_palette(
		$--700: $deepest-blue--700,
		$--800: $deepest-blue--800,
		$--900: $deepest-blue--900
	);
}
.orange {
	color: $orange--800 !important;
	@include color_palette(
		$--700: $orange--700,
		$--800: $orange--800,
		$--900: $orange--900
	);
}
.teal {
	color: $teal--800 !important;
	@include color_palette(
		$--700: $teal--700,
		$--800: $teal--800,
		$--900: $teal--900
	);
}
.purple {
	color: $purple--800 !important;
	@include color_palette(
		$--700: $purple--700,
		$--800: $purple--800,
		$--900: $purple--900
	);
}
.lime {
	color: $lime--800 !important;
	@include color_palette(
		$--700: $lime--700,
		$--800: $lime--800,
		$--900: $lime--900
	);
}
.yellow {
	color: $yellow--800 !important;
	@include color_palette(
		$--700: $yellow--700,
		$--800: $yellow--800,
		$--900: $yellow--900
	);
}
.warm-red {
	color: $warm-red--800 !important;
	@include color_palette(
		$--700: $warm-red--700,
		$--800: $warm-red--800,
		$--900: $warm-red--900
	);
}
.light-blue {
	color: $light-blue--800 !important;
	@include color_palette(
		$--700: $light-blue--700,
		$--800: $light-blue--800,
		$--900: $light-blue--900
	);
}
.dark-blue {
	color: $dark-blue--800 !important;
	@include color_palette(
		$--700: $dark-blue--700,
		$--800: $dark-blue--800,
		$--900: $dark-blue--900
	);
}
.light-purple {
	color: $light-purple--800 !important;
	@include color_palette(
		$--700: $light-purple--700,
		$--800: $light-purple--800,
		$--900: $light-purple--900
	);
}
.deep-purple {
	color: $deep-purple--800 !important;
	@include color_palette(
		$--700: $deep-purple--700,
		$--800: $deep-purple--800,
		$--900: $deep-purple--900
	);
}
.light-lime {
	color: $light-lime--800 !important;
	@include color_palette(
		$--700: $light-lime--700,
		$--800: $light-lime--800,
		$--900: $light-lime--900
	);
}
.bright-blue {
	color: $bright-blue--800 !important;
	@include color_palette(
		$--700: $bright-blue--700,
		$--800: $bright-blue--800,
		$--900: $bright-blue--900
	);
}
.dark-lime {
	color: $dark-lime--800 !important;
	@include color_palette(
		$--700: $dark-lime--700,
		$--800: $dark-lime--800,
		$--900: $dark-lime--900
	);
}
