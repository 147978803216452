@import '../../scss/core/vars.scss';
#pdiv {
.Button {
	cursor: pointer;

	.button {
		height: 48px;
		font-weight: $font-bold;
		border: $border-width solid;
		border-radius: $border-radius;
		transition: 0.1s ease-in;
	}
	.play-button {
		font-weight: $font-bold;
		border: none;
		border-radius: $border-radius;
		transition: 0.1s ease-in;
	}

	.button-type--main {
		border-color: $deepest-blue--800;
		color: $white;
		background-color: $deepest-blue--800;

		&:hover {
			background-color: $deepest-blue--700;
			box-shadow: 0px 0px 0px 4px rgba(61, 78, 112, 0.16);
			border-color: $deepest-blue--700;
		}
	}

	.button-type--soft {
		border-color: $dark-blue--800;
		background-color: $dark-blue--800;
		color: $white;
		&:hover {
			background-color: $dark-blue--700;
			box-shadow: 0px 0px 0px 4px rgba(147, 196, 205, 0.16);
			border-color: $dark-blue--700;
		}
	}
	.button-type--outline {
		border-color: $deepest-blue--800;
		color: $deepest-blue--800;
		background-color: $transparent;
		&:hover {
			background-color: $deepest-blue--700;
			box-shadow: 0px 0px 0px 4px rgba(61, 78, 112, 0.16);
			color: $white;
			border-color: $deepest-blue--700;
		}
	}
	.button-type--link {
		height: auto !important;
		border: none;
		padding: 0 !important;
		color: $deepest-blue--800;
		background-color: $transparent;
		font-size: $button-font-size--xl;
		line-height: 28px;
		transition: all 6s ease-out;

		svg {
			vertical-align: 12%;
			margin-left: 4px;
			fill: $deepest-blue--800;
		}
		&:hover {
			svg {
				fill: $deepest-blue--700;
				margin-left: 8px;
				margin-bottom: 4px;
			}
			color: $deepest-blue--700;
			text-decoration: underline;
		}
	}

	.padding--none {
		padding: 12px 24px 12px 24px;
	}
	.padding--both {
		padding: 12px 16px 12px 16px;
	}
	.padding--right {
		padding: 12px 16px 12px 24px;
	}
	.padding--left {
		padding: 12px 24px 12px 16px;
	}

	.icon-right {
		vertical-align: -30%;
		font-size: 28px;
		padding-left: 5px;
	}
	.icon-left {
		vertical-align: -30%;
		font-size: 28px;
		padding-right: 5px;
	}
	@media #{$mobile-phone} {
		.button {
			width: 100% !important;
		}
		.button-type--link {
			width: auto !important;
			font-size: $button-font-size--lg !important;
			line-height: 24px !important;
			svg {
				width: 10px !important;
				height: 10px !important;
			}
		}
	}
	.storybook-button--large {
		font-size: $button-font-size--lg;
		line-height: 24px;
		svg {
			width: 10px;
			height: 10px;
		}
	}

	.storybook-button--xl {
		font-size: $button-font-size--xl;
		line-height: 28px;
	}
}
}