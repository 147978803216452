#cookie-modal {
	position: fixed;
	z-index: 1000000;
	width: -moz-available;
	width: -webkit-fill-available;
	bottom: 0;
	background-color: #fff;
	margin: 0 40px;
	border-radius: 8px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
	opacity: 0.95;
	.flex-container {
		display: flex;
		align-items: center;

		.cancel-button {
			position: absolute;
			top: 40px;
			right: 24px;

			cursor: pointer;
			&:hover {
				color: #3d4e70;
			}
		}
		.message {
			flex-grow: 3;
			margin: 28px 40px;
			.cookie-policy {
				font-weight: bolder;
			}
		}
		.preferences-btn {
			flex-grow: 1;
			white-space: nowrap;
			margin: 24px 0;
		}
		.allow-cookie-btn {
			flex-grow: 1;
			white-space: nowrap;
			margin: 24px 72px 24px 16px;
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		.flex-container {
			display: flex;
			flex-wrap: wrap;

			.cancel-button {
				top: 29.42px;
				right: 29.43px;
			}

			.message {
				margin: 24px 64px 0 24px;
				width: -moz-available;
				width: -webkit-fill-available;
				.cookie-policy {
					font-weight: bolder;
				}
			}
			.preferences-btn {
				margin: 24px 0 24px 24px;
			}
			.allow-cookie-btn {
				margin: 24px 24px 24px 16px;
			}
		}
	}
	@media #{'only screen and (max-width : 767px)'} {
		.flex-container {
			display: flex;
			flex-wrap: wrap;

			.cancel-button {
				top: 24px;
				right: 16px;
			}

			.message {
				margin: 24px 48px 0 24px;
				width: -moz-available;
				width: -webkit-fill-available;
				.cookie-policy {
					font-weight: bolder;
				}
			}
			.preferences-btn {
				width: -moz-available;
				width: -webkit-fill-available;
				margin: 24px 24px 8px 24px;
			}
			.allow-cookie-btn {
				width: -moz-available;
				width: -webkit-fill-available;
				margin: 0px 24px 24px 24px;
			}
		}
	}
}

@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
	#cookie-modal {
		margin: 0 16px;
	}
}

@media #{'only screen and (max-width : 767px)'} {
	#cookie-modal {
		margin: 0 8px;
	}
}
