.bullet-points {
	.l,
	.m {
		margin-bottom: 8px;
	}
	.s,
	.xs {
		margin-bottom: 4px;
	}

	.bullet {
		display: inline-flex;
		svg &:hover path {
			fill: $deepest-blue--700;
		}
	}
}
