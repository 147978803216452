@use "./vars";
@use "sass:math";

@function stretch($width) {
	@if $width >0.5 {
		@return 1;
	}
	@return $width;
}

@mixin z-property {
	height: auto;
	position: relative;
	padding: 0px;
	box-sizing: border-box;
	margin: math.div($gutter-desktop, 2);
	@media #{$tablet} {
		margin: math.div($gutter-tablet, 2);
	}
	@media #{$mobile-phone} {
		margin: math.div($gutter-mobile-phone, 2);
	}
}
@mixin z-size($size, $gutter) {
	.z-item {
		@include z-property();
		flex-basis: calc((stretch(math.div(1, $size)) * 100%) - $gutter);
		&-mob {
			@media #{$mobile-phone} {
				@include z-property();
				flex-basis: calc(
					(stretch(math.div(1, $grids-in-mobile-phone)) * 100%) - $gutter
				) !important;
			}
		}
		&-tab {
			@media #{$tablet} {
				@include z-property();
				flex-basis: calc(
					(stretch(math.div(1, $grids-in-tablet)) * 100%) - $gutter
				) !important;
			}
		}
	}

	$inc: 1;
	@while $inc <=12 {
		$i: $inc;
		@if $inc > $size {
			$i: $size;
		}

		.z-item-#{$inc} {
			@include z-property();
			flex-basis: calc(((math.div($i, $size)) * 100%) - $gutter);
			@media #{$tablet} {
				@if $i == 3 {
					flex-basis: calc((stretch(math.div(4, $size)) * 100%) - $gutter);
				}
				flex-basis: calc((stretch(math.div($i, $size)) * 100%) - $gutter);
			}
			@media #{$mobile-phone} {
				flex-basis: calc((stretch(math.div($i, $size)) * 100%) - $gutter);
			}
			&-mob {
				@media #{$mobile-phone} {
					@include z-property();
					flex-basis: calc(
						((math.div($i, $grids-in-mobile-phone)) * 100%) - $gutter
					) !important;
				}
			}
			&-tab {
				@media #{$tablet} {
					@include z-property();
					flex-basis: calc(
						((math.div($i, $grids-in-tablet)) * 100%) - $gutter
					) !important;
				}
			}
		}
		$inc: $inc + 1;
	}
}
.z-contain {
	max-width: $container-max-width !important;
	margin: 0px $container-margin-desktop;
	padding: 0px;
	// width: auto;

	@media #{$more-than-1440} {
		margin: 0px
			calc($container-margin-desktop + (100% - $container-max-width) / 2);
	}

	@media #{$tablet} {
		margin: 0px $container-margin-tablet;
	}
	@media #{$mobile-phone} {
		margin: 0px $container-margin-mobile;
	}

	.z-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;

		@include z-size($grids-in-desktop, $gutter-desktop);
		@media #{$tablet} {
			@include z-size($grids-in-tablet, $gutter-tablet);
		}
		@media #{$mobile-phone} {
			@include z-size($grids-in-mobile-phone, $gutter-mobile-phone);
		}
	}

	.box {
		border: 1px solid black;
	}
}
