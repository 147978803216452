@import './vars.scss';



a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.justify-space-between {
	justify-content: space-between !important;
}

.show-on-desktop {
	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		display: none;
	}
	@media #{'only screen and (max-width : 767px)'} {
		display: none;
	}
}

.show-on-desktop {
	display: block;
}
.show-on-mobile {
	display: none;
}
.show-on-tab {
	display: none;
}

@media #{$mobile-phone} {
	.hide-on-mobile {
		display: none;
	}
	.show-on-desktop {
		display: none;
	}
	.show-on-mobile {
		display: block;
	}
}

@media #{$tablet} {
	.hide-on-tab {
		display: none;
	}

	.show-on-desktop {
		display: none;
	}
	.show-on-tab {
		display: block;
	}
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	/* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

// .section {
// 	padding-top: 60px;
// 	padding-bottom: 60px;
// 	// margin-top: 60px;
// 	// margin-bottom: 60px;
// 	// 64 padding in phone
// 	// // margin tb  60
// 	// 40 phone

// 	@media #{$mobile-phone} {
// 		// padding-top: 64px;
// 		// padding-bottom: 64px;
// 		padding-top: 40px;
// 		padding-bottom: 40px;
// 	}
// }

// .feat-section {
// 	padding-top: 80px;
// 	padding-bottom: 80px;
// 	margin-top: 20px;
// 	margin-bottom: 20px;
// 	margin-left: 40px;
// 	margin-right: 40px;
// 	// 64 padding in phone

// 	// // margin tb 20
// 	// 24 phone

// 	@media #{$mobile-phone} {
// 		padding-top: 64px;
// 		padding-bottom: 64px;
// 		margin-top: 24px;
// 		margin-bottom: 24px;
// 	}

// 	.z-contain {
// 		max-width: $container-max-width !important;
// 		margin: 0px 80px;
// 		padding: 0px;
// 	}

// 	@media #{$more-than-1440} {
// 		.z-contain {
// 			margin: 0px
// 				calc(
// 					$container-margin-desktop + (100% - $container-max-width) / 2 - 40px
// 				);
// 		}
// 	}

// 	@media #{$tablet} {
// 		margin-left: 20px;
// 		margin-right: 20px;
// 		.z-contain {
// 			margin: 0px calc($container-margin-tablet - 20px);
// 		}
// 	}
// 	@media #{$mobile-phone} {
// 		margin-left: 0px;
// 		margin-right: 0px;
// 		.z-contain {
// 			margin: 0px $container-margin-mobile;
// 		}
// 	}
// }

.font-roboto-slab {
	font-family: 'Roboto Slab';
}

.font-bold {
	font-weight: 700;
}

.font-light {
	font-weight: 300;
}

.blue-text {
	color: #00aeef;
}

.black-text {
	color: #19202e;
	opacity: 1 !important;
}

.text-uppercase {
	text-transform: uppercase;
}

.respImage {
	position: absolute;
	max-width: 600px;
	right: 0px;

	@media #{$mobile-phone} {
		width: 100%;
		position: relative;
	}
}

.overflow-hidden {
	overflow: 'hidden';
}

.justify-center {
	justify-content: center;
}
