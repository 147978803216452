// Core SCSS

#pdiv {
	all:revert;
	padding: 0 !important;
	margin: 0 !important;
	font-family: 'Roboto', sans-serif;
	color: #2f2f2f;
}


#pdiv {
@import './core/base.scss';
@import './core/vars.scss';
@import './core/color.scss';
@import './core/typography.scss';
@import './core/grid.scss';
@import './core/bullet.scss';
@import './core/cookieModal.scss';
@import './core/cookiePreferences.scss';
@import './core/accordion.scss';
@import './core/toggle.scss';

}



#cookie {
	position: fixed;
	width: 100%;
	z-index: 1000000;
	bottom: 0;

	.z-contain {
		border-radius: 8px;
		background-color: #121212;

		.body-s {
			color: #ffffff;
			padding: 20px;
		}

		.z-item-2 {
			padding: 5px;
		}

		.cookie-policy {
			font-weight: bolder;
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		.z-contain {
			.z-item-2 {
				padding: 12px;
			}

			.Button .padding--none {
				padding: 12px !important;
			}
		}

		bottom: 10%;
	}

	@media #{'only screen and (max-width : 767px)'} {
		bottom: 5%;
	}
}

