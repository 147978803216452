#pdiv {
#innoNav {
	background-color: #ffffff;
	// padding: 6px 0px;
	position: fixed;
	width: 100%;
	z-index: 1000000;
	transition: top 0.3s ease-in-out;

	.navbar {
		display: block;

		.logo-wrapper {
			display: flex;
			align-items: center;
		}

		.topHeader {
			display: flex;
			flex-wrap: inherit;
			align-items: center;
			justify-content: space-between;
		}

		.logo-separator {
			border-left: 1px solid #d5d5d5;
			height: 32px;
		}

		.careers-logo {
			width: 105px;
			cursor: pointer;

			@media #{'only screen and (max-width : 767px)'} {
				svg {
					width: 90px !important;
				}
			}
		}

		.showmob {
			display: none !important;
		}

		@media #{'only screen and (max-width : 767px)'} {
			.hidemob {
				display: none !important;
			}

			.showmob {
				display: block !important;
			}
		}
	}

	.navbar-brand {
		display: inline-block;
		padding-top: 0px;
		padding-bottom: 0px;
		margin-right: 0px;
		font-size: 0px;
		line-height: inherit;
		white-space: nowrap;
	}

	.navbar-nav li:hover>div.dropdown-menu {
		display: block;
	}

	.nav-item {
		.nav-link {
			font-weight: 700;
			color: #19202e;
			padding: 14px 20px !important;
		}

		.button-type--link {
			font-size: 16px;
			line-height: 24px;

			svg {
				width: 10px;
				height: 10px;
			}
		}

		&.show {
			.nav-link {
				font-weight: 700;
				color: #0070DD;
			}
		}

		&:hover {
			.nav-link {
				color: #0070DD;
			}
		}
	}

	.ml-auto {
		margin-left: auto !important;
	}

	.nav-link-btn {
		padding-left: 40px;

		.Button {
			.button-type--main {
				background: #0070DD !important;
				border-color: #0070DD;

			}
		}
	}

	.dropdown-menu {
		filter: drop-shadow(0px 4px 24px rgba(47, 47, 47, 0.12));
		// background-color: #ffffff;
		width: 1200px;
		border-radius: 8px;
		padding: 0px;
		// top: 65px;
		// left: 50%;
		// transform: translateX(-50%);
		margin: 0;

		.leftLink {
			padding: 32px;
		}

		.item-1 {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-right: 1px solid #d5d5d5;
			background-color: #f4f4f4;
		}

		.item-2 {
			border-right: 1px solid #d5d5d5;
		}

		.middleLink {
			padding: 24px 25px 40px 25px;

			.navCard {
				padding: 16px;

				.navTitle {
					color: #121010;
					display: flex;
					align-items: center;
				}

				.multi-arrow {

					content: url('./images/arrow.svg');


				}

				.navBody {
					color: #6d6d6d;
				}

				&:hover {
					background: #f4f4f4;
					border-radius: 8px;
					cursor: pointer;

					.navTitle {
						color: #0070DD;

						// .nav-arrow {
						// 	g {
						// 		path {
						// 			fill: #0070DD;

						// 		}
						// 	}
						// }
						.multi-arrow {



							content: url('./images/extendedArrow.svg') !important;


						}
					}

				}
			}

			.navCompanyCard {
				padding: 16px;

				.navCompanyTitle {
					color: #19202e;
				}

				.navCompanyBody {
					color: #6d6d6d;
				}
			}
		}

		.rightLink {
			padding: 24px 32px;

			.head {
				color: #595959;
				font-weight: 600;
				text-transform: uppercase;
			}

			.navCard {
				margin-top: 24px;

				.navTitle {
					color: #6d6d6d;
					text-transform: uppercase;
				}

				.navContent {
					color: #19202e;
				}
			}
		}

		.subMenu {
			padding: 12px 32px;

			&:hover {
				background-color: #0070DD;
				color: #ffffff;
				cursor: pointer;
			}
		}

		.activeSubMenu {
			background-color: #0070DD;
			color: #ffffff;
		}

		&.double-column-1 {
			left: calc(100% - 524px);
		}

		&.double-column-2 {
			left: calc(100% - 646px);
		}

		&.double-column-3 {
			left: calc(100% - 738px);
		}

		&.double-column-4 {
			left: calc(100% - 868px);
		}

		&.double-column-5 {
			left: calc(100% - 989px);
		}

		&.double-column-uk-1 {
			left: calc(100% - 738px);
		}

		&.double-column-uk-2 {
			left: calc(100% - 859px);
		}

		&.double-column-uk-3 {
			left: calc(100% - 989px);
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		.collapse:not(.show) {
			display: none !important;
		}

		.navbar-nav {
			flex-direction: column;
			justify-content: space-between;
		}

		.navbar-expand-lg .navbar-toggler {
			display: block;
		}

		.navbar-collapse {
			display: block !important;
			flex-basis: 100% !important;
		}

		.navbar-expand-lg {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	@media #{'only screen and (min-width : 1025px) and (max-width : 1380px)'} {
		.nav-item .nav-link {
			padding: 14px 12px !important;
		}

		.nav-link-btn {
			padding-left: 20px;
		}

		.dropdown-menu {
			width: 1032px;

			&.double-column-1 {
				left: calc(100% - 440px);
			}

			&.double-column-2 {
				left: calc(100% - 547px);
			}

			&.double-column-3 {
				left: calc(100% - 622px);
			}

			&.double-column-4 {
				left: calc(100% - 737px);
			}

			&.double-column-5 {
				left: calc(100% - 843px);
			}

			&.double-column-uk-1 {
				left: calc(100% - 737px);
			}

			&.double-column-uk-2 {
				left: calc(100% - 843px);
			}
		}
	}

	@media #{'only screen and (max-width : 768px)'} {
		.navbar-nav {
			justify-content: space-between !important;
		}
	}
}

.top-88 {
	top: 87px;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		top: 101px;
	}

	@media #{'only screen and (max-width : 767px)'} {
		top: 151px;
	}
}

.top-0 {
	top: 0px;
}

#mobNav {
	margin-top: 20px;

	.navLink {
		padding: 16px 25px;
		font-weight: 500;

		@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
			padding-right: 4em;
		}

		@media #{'only screen and (max-width : 767px)'} {
			padding-right: 3em;
		}
	}

	.navLink-dropdown {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px 25px;
		padding-right: 25px;
		font-weight: 500;

		.dropdown-icon {
			display: none;
		}

	}

	.careers-navLink {
		padding: 16px 25px;
		font-weight: 500;

		&:hover {
			color: #0070DD;
			cursor: pointer;
		}
	}

	.drop-down {
		display: none;
		// display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		a {
			width: 100%;

			.drop-data {
				padding: 16px 25px;

				.drop-icon {
					margin-right: 16px;
				}
			}
		}

	}

	.activeSubMenu {
		background-color: #0070DD;
		color: #ffffff;
	}
}

.mob-btn {
	margin-bottom: 100px;

	.Button {
		.button-type--main {
			background: #0070DD !important;
			border-color: #0070DD;

		}
	}
}

.navbar-toggler {
	padding: 0px 8px;
	background-color: transparent;
	border: none;
	border-radius: 0px;
}

.navbar-toggler {
	&:focus {
		box-shadow: none;
	}
}

.multi-links {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 16px;

	.multi-links-img {
		margin-top: 4px;
		width: 10%;
	}

	.multi-links-div {
		width: 85%;

		.multi-links-title {
			color: #212433;
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
		}

		.multi-links-desc {
			color: #595250;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
		}
	}

	&:hover {
		background: #f4f4f4;
		border-radius: 8px;
		cursor: pointer;

		.multi-links-title {
			color: #0070DD;
		}

	}


}

.marketing {
	width: 100%;
	height: auto;
}

.show-on-desktop {
	z-index: 100;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		display: none;
	}

	@media #{'only screen and (max-width : 767px)'} {
		display: none;
	}
}

.sara-nav-transp-control {
	background: transparent !important;

	@media #{'only screen and (max-width : 1024px)'} {
		background: #ffffff !important;
	}
}

.sara-nav-normal-bg {
	background: #ffffff;
}

.swiper-height {
	min-height: 500px;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		min-height: 500px;
	}

	@media #{'only screen and (max-width : 767px)'} {
		min-height: 500px;
	}
}

#svi-map {
	.states {
		&:hover {
			background: red;
		}
	}
}

.arrow {
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid #19202e;
	border-radius: 0.4px;
	margin-top: auto;
	margin-bottom: auto;
}

.justify-space-between {
	justify-content: space-between;
}

.align-items {
	align-items: center;
}

.resource-links {
	display: flex;
	position: absolute;
	left: 9.5em;
	top: 7em;

	font-weight: 600;
	opacity: 0.5;
	text-transform: uppercase;
	list-style-type: none;

	@media #{'only screen and (max-width : 767px)'} {
		left: 1em;
		font-size: 13px;
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		left: 2em;
	}
}

.download-link {
	&:hover {
		opacity: 0.8;
	}
}

.routes {
	width: 100%;

	font-weight: 600;
	opacity: 0.5;
	display: flex;
	flex-direction: row;
	text-transform: uppercase;
	list-style-type: none;
}

.respButton {
	display: flex;
	flex-direction: row;

	@media #{'only screen and (max-width : 1024px)'} {
		display: flex;
		flex-direction: column;

		>div {
			padding-bottom: 8px;
		}
	}
}

.sara-top-sec-margin {
	margin-top: 32px !important;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-top: 36px !important;
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 36px !important;
	}
}

.sara-hello-bar-top-sec-margin {
	margin-top: 36px !important;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-top: 18px !important;
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 28px !important;
	}
}

.margins-top-hero-section {
	margin-top: 87px !important;

	@media #{'only screen and (max-width : 1024px)'} {
		margin-top: 80px !important;
	}
}

.respAboutImg {
	img {
		width: 100%;
	}
}

.respImg10 {
	width: 270%;
	margin-left: -30%;
	transform: translateY(44px);
	max-height: 800px;

	img {
		width: 100%;
		height: auto !important;
	}

	@media (min-width: 1600px) {
		width: 175%;
		transform: translateY(100px);
	}

	@media (max-width: 1000px) {
		margin-left: 0px;
		transform: translateY(-75px);
	}
}

.respImg {
	width: 270% !important;
	margin-left: -30%;
	transform: translateY(72px) !important;
	max-height: 800px !important;

	img {
		width: 100% !important;
		height: auto !important;
	}

	@media (min-width: 1600px) {
		width: 175% !important;
		transform: translateY(100px) !important;
	}

	@media (max-width: 1000px) {
		margin-left: 0px;
		transform: translateY(-75px) !important;
	}
}

.respCloudImg {

	// transform: translateY(50px);
	img {
		width: 100%;
		height: auto !important;
		margin-left: -4em;
	}

	@media #{'only screen and (min-width : 1440px)'} {
		img {
			margin-left: -6em;
		}
	}
}

.toolkit-hero-text {
	position: absolute;
	left: 0.5em;
	top: 5em;
}

#resource-card-section {
	@media #{'only screen and (max-width : 767px)'} {
		.resource-cards:not(:first-child) {
			display: none;
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		.resource-cards:nth-child(n + 3) {
			display: none;
		}
	}
}

@media #{'only screen and (max-width : 767px)'} {
	.card-heading {
		margin-bottom: 10px !important;
	}

	.text-center-mobile {
		text-align: center;
	}
}

@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
	.card-heading {
		text-align: center;
	}
}

@media #{'only screen and (max-width : 767px)'} {
	.mpr-45 {
		padding-right: 45px !important;
	}
}

.separator {
	border-top: 1px solid #d5d5d5;
}

.section {
	padding-top: 60px;
	padding-bottom: 60px;
	margin-top: 0px;
	margin-bottom: 0px;

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.feat-section {
	padding-top: 80px;
	padding-bottom: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 40px;
	margin-right: 40px;

	.z-contain {
		margin: 0px 80px;
	}

	@media #{'only screen and (max-width : 767px)'} {
		padding-top: 64px;
		padding-bottom: 64px;
		margin-top: 24px;
		margin-bottom: 24px;
		margin-left: 0px;
		margin-right: 0px;

		.z-contain {
			margin: 0px 24px;
		}
	}

	@media #{'only screen and (min-width : 1441px)'} {
		.z-contain {
			margin: 0px calc(120px + (100% - 1440px) / 2);
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-left: 20px;
		margin-right: 20px;

		.z-contain {
			margin: 0px 20px;
		}
	}
}

.article-links {
	margin-left: 6px !important;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-left: 8px !important;
	}
}

.article-wrapper {
	justify-content: center !important;
}

.article-item-wrapper {
	align-items: center !important;
}

.article-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.video-article-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.article-text {
	mix-blend-mode: normal;
	opacity: 0.8;
	color: #595959;
}

.article-img-wrapper {
	position: relative;
	bottom: 5rem;

	img {
		border-radius: 12px;
		width: 100%;
	}
}

.profile-img {
	border-radius: 50%;
	width: 100%;
}

.quoted-text {
	border-left: 8px solid #82c91e;
	border-radius: 8px;
	padding: 24px 32px 24px 40px !important;
}

.separator {
	border-top: 1px solid #d5d5d5;
}

.conf-separator {
	border-top: 1px solid #d5d5d5;
	margin-top: 64px !important;

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 40px;
	}
}

.article-list {
	list-style-image: url('https://storage.googleapis.com/staging-incrowd//png/done/1656579463112/file.png?updated_at=2022-06-30T08:57:47.767Z');
}

.email-section {
	padding: 56px 0px 40px 100px;
}

.recommend-section {
	padding: 48px 0px 48px 100px !important;
	margin-right: 96px !important;
}

.recommend-img {
	// padding-left: 16px !important;

	@media #{'only screen and (min-width : 1440px)'} {
		padding-left: 86px !important;
	}

	img {
		height: 100%;
		width: 100%;
		// transform: translateY(-20px);
		border-radius: 12px;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
	}
}

@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
	.article-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end !important;
	}

	.video-article-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start !important;
	}

	.email-section {
		padding: 50px;
	}

	.recommend-section {
		padding: 15px !important;
		margin-right: 0px !important;
	}

	.recommend-img {
		order: -1;
		padding: 15px !important;

		img {
			height: 100%;
			transform: translateY(0px);
		}
	}
}

@media #{'only screen and (max-width : 767px)'} {
	.article-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 16px !important;
	}

	.video-article-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 16px !important;
	}

	.email-wrapper {
		display: flex;
		flex-direction: column !important;
	}

	.email-section {
		padding: 56px 16px 16px;
	}

	.recommend-section {
		padding: 15px !important;
		margin-right: 0px !important;
	}

	.recommend-img {
		order: -1;
		padding: 15px !important;
		padding-right: 0px !important;

		img {
			width: 300px;
			height: 100%;
			transform: translateY(0px);
		}
	}
}

.icon {
	cursor: pointer;

	svg {
		g {
			opacity: 0.5;
		}

		path {
			fill: #3d4e70;
		}
	}

	&:hover {
		svg {
			g {
				opacity: 1;
			}

			circle {
				fill: #3d4e70;
				stroke: #3d4e70;
			}

			path {
				fill: #ffffff;
			}
		}
	}
}

.pdf {
	.cancel-button {
		display: none !important;
	}
}

.media {
	.video-js {
		border-radius: 12px;

		iframe {
			border-radius: 12px;
		}
	}

	.vjs-control-bar {
		border-radius: 12px;
	}

	.vjs-poster {
		border-radius: 12px;
	}
}

.video {
	z-index: 1000000 !important;

	.media {
		position: relative;
		width: 100%;
		height: 560px;

		@media (max-width: 1024px) {
			width: 100%;
			height: 400px;
		}

		.video-js {
			border-radius: 12px;

			iframe {
				border-radius: 12px;
			}
		}

		.vjs-control-bar {
			border-radius: 12px;
		}

		.vjs-poster {
			border-radius: 12px;
		}

		.cancel-button {
			display: none;
			z-index: 9;
			background: radial-gradient(white 30%, black);
			top: 24px !important;
			right: 24px !important;
			left: auto !important;
			color: black;
			font-size: 40px;
			border-radius: 12px;
		}

		&:hover {
			.cancel-button {
				display: block;
				opacity: 80%;
			}
		}

		.vjs_video_3-dimensions {
			width: 100%;
			height: 560px;

			@media (max-width: 1024px) {
				width: 100%;
				height: 400px;
			}
		}
	}
}

.award {
	button {
		display: none;
	}
}

#awardSection {
	button {
		display: none;
	}
}

.show-button-award {
	#awardSection {
		button {
			display: initial !important;
		}
	}
}

.hero-section-placement {
	padding-top: 20px !important;
	margin-top: 67px !important;

	@media #{'only screen and (min-width : 1024px)'} {
		padding-top: 20px !important;
		margin-top: 87px !important;
	}
}

.Resource-card {
	.bottom {
		.button {
			height: 24px;
		}
	}
}

.feat-section-positioning {
	margin-top: 84px !important;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-top: 70px !important;
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 60px !important;
	}
}

.video-section-positioning {
	margin-top: 60px !important;

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-top: 40px !important;
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin-top: 40px !important;
	}
}

#privacy {
	ul {
		list-style: none;
		padding: 0;
	}

	li {
		padding-left: 1.3em;
	}

	li:before {
		content: 'done';
		font-family: 'Material Icons';
		display: inline-block;
		margin-left: -1.3em;
		width: 1.3em;
	}
}

#cookie {
	position: fixed;
	width: 100%;
	z-index: 1000000;
	bottom: 0;

	.z-contain {
		border-radius: 8px;
		background-color: #121212;

		.body-s {
			color: #ffffff;
			padding: 20px;
		}

		.z-item-2 {
			padding: 5px;
		}

		.cookie-policy {
			font-weight: bolder;
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		.z-contain {
			.z-item-2 {
				padding: 12px;
			}

			.Button .padding--none {
				padding: 12px !important;
			}
		}

		bottom: 10%;
	}

	@media #{'only screen and (max-width : 767px)'} {
		bottom: 5%;
	}
}

.justify-center {
	justify-content: center !important;
}

.about-multi-wrapper {
	.respImg {
		width: 116%;

		@media #{'only screen and (max-width : 767px)'} {
			width: 100%;
		}

		@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
			width: 110%;
		}
	}
}

.about-carousel-wrapper {
	img {
		height: 112px;

		@media #{'only screen and (max-width : 767px)'} {
			height: 96px;
		}

		@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
			height: 98px;
		}

		@media #{'only screen and (min-width : 1440px)'} {
			height: 133px;
		}
	}
}

.hello-margins {
	margin-bottom: 174px;

	@media #{'only screen and (max-width : 767px)'} {
		margin-bottom: 208px !important;
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-bottom: 167px;
	}
}

.sol-event-card {
	background-color: #ffffff;
	border-radius: 12px;
}

.Form {
	.form-div {
		z-index: 300000000 !important;
	}
}

#partner-card-section {
	.resource-cards {
		background-color: #ffffff;
		border-radius: 12px;
	}
}

.partner-rs {
	.card-wrapper {
		height: 356px !important;
	}
}

.partner-ocs {
	#partner-card-section {
		.event-card {
			@media #{'only screen and (max-width : 767px)'} {
				height: 264px;
			}

			@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
				height: 267px;
			}
		}
	}
}

.podcast-form {
	.form {
		i {
			display: none !important;
		}
	}
}

.heroSectionwithoutimg {
	@media #{'only screen and (max-width : 767px)'} {
		.mr-16 {
			margin-right: 0 !important;
		}
	}
}

.section-md {
	padding-top: 120px;
	padding-bottom: 120px;
}

.formButton {
	background-color: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -25px;
	padding: 24px;
	z-index: 2;
	position: fixed;
	bottom: 2px;
	width: 100%;

	@media #{'only screen and (min-width : 768px)'} {
		display: none;
	}
}

.conf-section {
	#resource-section>.resource-section-swiper .swiper {
		height: 32em !important;

		@media #{'only screen and (min-width : 500px) and (max-width : 620px)'} {
			height: 36em !important;
		}

		@media #{'only screen and (min-width : 621px) and (max-width : 766px)'} {
			height: 40em !important;
		}
	}
}

.resp-section {
	width: 50%;

	@media #{'only screen and (max-width : 1024px)'} {
		width: 100%;
	}
}

.points-display {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	svg {
		width: 3%;
	}

	span {
		width: 97%;
	}

	@media #{'only screen and (max-width : 767px)'} {
		svg {
			width: 10%;
		}

		span {
			width: 90%;
		}
	}
}

.points-display-multi {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	svg {
		margin-top: 10px;

		width: 5%;
	}

	span {
		width: 95%;
		text-align: left;
	}

	@media #{'only screen and (max-width : 767px)'} {
		svg {
			width: 10%;
		}

		span {
			width: 90%;
		}
	}
}

.respeCQMImg {
	width: 175%;
	margin-left: -20%;
	// transform: translateY(44px);
	max-height: 800px;

	img {
		width: 100%;
		height: auto !important;
	}

	@media (min-width: 1600px) {
		width: 175%;
		// transform: translateY(100px);
	}

	@media (max-width: 1000px) {
		margin-left: 0px;
		transform: translateY(-75px);
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		transform: translateY(10px);
	}

	@media (max-width: 786px) {
		margin-left: 0px;
		transform: translateY(10px);
	}
}

.hero-link {
	color: #0070dd;
	cursor: pointer;

	&:hover {
		color: #338de4;
		text-decoration: underline;
	}
}

.analysticsSection {
	background-color: #212433;
	border-radius: 12px;
	overflow: hidden;
	margin: 0 40px;

	.play-button-on-img {
		height: 300px;
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin: 0px 0px;
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin: 0px 0px;

		.play-button-on-img {
			height: 200px;
		}
	}
}

.multi-sec-me {
	.MultiSection .subhead {
		text-align: center;
		margin-top: 24px !important;
	}
}

.demo_form {
	max-height: calc(100vh - 100px);
	overflow: auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.sara-sec {
	margin-bottom: 160px;
	overflow: hidden;
	position: relative;
	width: 100%;

	.sara-swiper {
		.swiper-wrapper {
			-webkit-transition-timing-function: linear !important;
			transition-timing-function: linear !important;
		}

		.swiper-slide {
			height: 110px;
			width: fit-content !important;

			span {
				// background: linear-gradient(115deg, #ffc208 8.5%, #e31c79 82.8%);
				background: linear-gradient(105deg,
						#eb5fa1 14.95%,
						#d93737 52.89%,
						#57d1ff 87.8%);

				font-size: 96px;
				line-height: 86px;
				letter-spacing: -2px;
				font-weight: 500;
				font-style: normal;
				background-clip: text;
				-webkit-background-clip: text;
				color: transparent;
				margin-right: 48px;
			}
		}
	}

	.outline-btn {
		padding: 8px 16px 8px 24px;
		border-radius: 24px;
		border: 2px solid #c5c5c5;
		width: fit-content;
		margin: 0 auto;
		cursor: pointer;
		margin-bottom: 34px;
		margin-top: 40px;

		a {
			display: flex;
			align-items: center;
		}

		&:hover {
			background: #f7f6f5;

			a {
				color: #595959 !important;
				text-decoration: none;
			}
		}
	}

	.Button .button-type--main {
		background-color: #0070dd;
		border: none;

		&:hover {
			box-shadow: none;
			background-color: #338de4;
		}
	}

	@media #{'only screen and (max-width : 767px)'} {
		margin-bottom: 80px;

		.outline-btn {
			font-size: 12px !important;
			margin: 24px 0px 16px;
		}

		.h1 {
			text-align: left;
			font-size: 32px;
			line-height: 40px;
		}

		.sara-swiper {
			.swiper-slide {
				width: 740px !important;
				height: 70px;

				span {
					font-size: 64px !important;
					line-height: 64px !important;
					margin-right: 0px;
				}
			}
		}
	}

	@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
		margin-bottom: 80px;

		.h1 {
			font-size: 40px;
			line-height: 48px;
		}

		.outline-btn {
			margin-bottom: 24px;
		}

		.sara-swiper {
			.swiper-slide {
				width: 1150px !important;
				height: 98px;

				span {
					font-size: 96px !important;
					line-height: 88px !important;
				}
			}
		}
	}

	@media #{'only screen and (min-width : 1024px) and (max-width : 1300px)'} {
		.sara-swiper {
			.swiper-slide {
				width: 1140px !important;
			}
		}
	}
}

#AdvisoryPersonCard {

	.pcard {
		padding: 16px;
		border: none;
		cursor: pointer;
		border: 1px solid transparent;
		transition: all .3s ease;

		&:hover {

			box-shadow: 0px 2px 4px 0px #00000029;
			border-radius: 12px;
			border: 1px solid #F3F2F2
		}
	}

	.icon {
		cursor: pointer;

		svg {
			g {
				opacity: 0.5;

			}

			circle {
				fill: #ffffff;
			}

			path {
				fill: #19202E;
			}

		}

		&:hover {
			svg {
				g {
					opacity: 1;

				}

				circle {
					fill: #0070dd;
				}

				path {
					fill: #ffffff;
				}
			}
		}
	}

	@media #{'only screen and (max-width : 767px)'} {
		.pcard {
			padding: 12px;

		}
	}
}}