@use "sass:meta";
@use "./vars";

@mixin attributes($args...) {
	@each $name, $i in meta.keywords($args) {
		#{$name}: $i;
	}
}

.title {
	font-family: $font-roboto;
	font-weight: $font-light;
	font-size: 68px;
	line-height: 72px;
	letter-spacing: -0.03em;
	color: $grey--900;
	&-small {
		font-family: $font-roboto;
		font-weight: $font-light;
		letter-spacing: -0.03em;
		color: $grey--900;
		font-size: 48px;
		line-height: 56px;
	}
	@media #{$mobile-phone} {
		font-size: 48px;
		line-height: 56px;
	}
}

.h1 {
	font-family: $font-roboto;
	font-weight: $font-light;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: -0.02em;
	color: $grey--900;

	&-small {
		font-family: $font-roboto;
		font-weight: $font-light;
		font-size: 40px;
		line-height: 48px;
		letter-spacing: -0.01em;
		color: $grey--900;
	}

	@media #{$mobile-phone} {
		font-size: 40px;
		line-height: 48px;
		letter-spacing: -0.01em;
	}
}

.h2 {
	font-family: $font-roboto;
	font-weight: $font-light;
	font-size: 40px;
	line-height: 48px;
	letter-spacing: -0.01em;
	color: $grey--900;
	&-small {
		font-family: $font-roboto;
		font-weight: $font-light;
		letter-spacing: -0.01em;
		color: $grey--900;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: -0.01em;
	}

	@media #{$mobile-phone} {
		font-size: 32px;
		line-height: 40px;
	}
}
.h3 {
	font-family: $font-roboto-slab;
	font-weight: $font-semi-dark;
	font-size: 32px;
	line-height: 40px;
	color: $grey--900;
	&-small {
		font-family: $font-roboto-slab;
		font-weight: $font-semi-dark;
		color: $grey--900;
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
	}

	@media #{$mobile-phone} {
		font-weight: $font-semi-dark;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: -0.01em;
	}
}
.h4 {
	font-family: $font-roboto;
	font-weight: $font-dark;
	font-size: 24px;
	line-height: 32px;
	color: $grey--900;
	&-small {
		font-family: $font-roboto;
		font-weight: $font-dark;
		color: $grey--900;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.01em;
	}
}

.h5 {
	font-family: $font-roboto;
	font-weight: $font-dark;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.01em;
	color: $grey--900;
	&-small {
		font-family: $font-roboto;
		font-weight: $font-semi-bold;
		letter-spacing: -0.01em;
		color: $grey--900;
		font-size: 16px;
		line-height: 22px;
	}
}

.h6 {
	font-family: $font-roboto;
	font-weight: $font-dark;
	font-size: 16px;
	line-height: 22px;
	color: $grey--900;
	&-small {
		font-family: $font-roboto;
		font-weight: $font-semi-bold;
		letter-spacing: -0.01em;
		color: $grey--900;
		font-size: 16px;
		line-height: 22px;
	}
}

%body-parent {
	@include attributes(
		$font-family: $font-roboto,
		$font-weight: $font-semi-dark,
		$color: $grey--700
	);
}
.-link {
	&:hover {
		color: $deepest-blue--700 !important;
		text-decoration: underline !important;
		cursor: pointer;
	}
}
.-modern {
	font-family: $font-roboto-slab !important;
}

.body {
	@extend %body-parent;
	font-size: 20px;
	line-height: 28px;
	&-l {
		@extend %body-parent;
		@include attributes($font-size: 24px, $line-height: 32px);
	}
	&-m {
		@extend %body-parent;
		@include attributes($font-size: 20px, $line-height: 28px);
	}
	&-s {
		@extend %body-parent;
		@include attributes($font-size: 16px, $line-height: 22px);
	}
	&-xs {
		@extend %body-parent;
		@include attributes($font-size: 14px, $line-height: 20px);
	}
}

%overline-parent {
	@include attributes(
		$font-family: $font-roboto,
		$letter-spacing: 0.04em,
		$text-transform: uppercase,
		$color: $bright-blue--800
	);
}

.overline {
	@extend %overline-parent;
	font-weight: $font-bold;
	font-size: 20px;
	line-height: 24px;

	&-l {
		@extend %overline-parent;
		font-weight: $font-dark;
		font-size: 24px;
		line-height: 28px;
	}
	&-m {
		@extend %overline-parent;
		font-weight: $font-dark;
		font-size: 20px;
		line-height: 24px;
	}
	&-s {
		@extend %overline-parent;
		font-weight: $font-semi-bold;
		font-size: 16px;
		line-height: 20px;
	}
	&-xs {
		@extend %overline-parent;
		font-weight: $font-semi-dark;
		font-size: 14px;
		line-height: 18px;
	}
}
