//display-size-variables
$more-than-1440: 'only screen and (min-width : 1441px)';
$mobile-phone: 'only screen and (max-width : 767px)';
$tablet: 'only screen and (min-width : 768px) and (max-width : 1024px)';
$container-max-width: 1440px;

//grid-variables
$container-margin-desktop: 120px;
$container-margin-tablet: 40px;
$container-margin-mobile: 24px;
$grids-in-desktop: 12;
$grids-in-tablet: 8;
$grids-in-mobile-phone: 4;
$gutter-desktop: 16px;
$gutter-tablet: 16px;
$gutter-mobile-phone: 8px;

//color variables
$white: #ffffff;
$black: black;
$transparent: transparent;
$grey--BG: #f3f3f3;
$grey--100: #d5d5d5;
$grey--200: #c1c1c1;
$grey--300: #acacac;
$grey--400: #979797;
$grey--500: #828282;
$grey--600: #6d6d6d;
$grey--700: #595959;
$grey--800: #444444;
$grey--900: #2f2f2f;
$ruby-red--700: #ee77af;
$ruby-red--800: #e31c79;
$ruby-red--900: #9f1455;
$deepest-blue--700: #3d4e70;
$deepest-blue--800: #19202e;
$deepest-blue--900: #07090d;
$orange--700: #ffc866;
$orange--800: #ffa400;
$orange--900: #b27300;
$teal--700: #8bdacd;
$teal--800: #40c1ac;
$teal--900: #2c8778;
$purple--700: #cf53ae;
$purple--800: #942977;
$purple--900: #581847;
$lime--700: #b1e864;
$lime--800: #82c91e;
$lime--900: #568514;
$yellow--700: #fede76;
$yellow--800: #fed141;
$yellow--900: #f4ba01;
$warm-red--700: #f3a287;
$warm-red--800: #e95a29;
$warm-red--900: #b43c13;
$light-blue--700: #c8eaef;
$light-blue--800: #a1dbe4;
$light-blue--900: #64c4d3;
$dark-blue--700: #93c4cd;
$dark-blue--800: #4e9caa;
$dark-blue--900: #376d77;
$light-purple--700: #e0a4cb;
$light-purple--800: #c657a0;
$light-purple--900: #9c3579;
$deep-purple--700: #b292b5;
$deep-purple--800: #835b86;
$deep-purple--900: #563c58;
$light-lime--700: #f3ff47;
$light-lime--800: #d0df00;
$light-lime--900: #8a9400;
$bright-blue--700: #57d1ff;
$bright-blue--800: #00aeef;
$bright-blue--900: #0077a3;
$dark-lime--700: #9fcba2;
$dark-lime--800: #5da863;
$dark-lime--900: #407745;

//font variables //to be updated
$font-roboto: 'Roboto';
$font-roboto-slab: 'Roboto Slab';
$font-bold: 700;
$font-semi-bold: 600;
$font-dark: 500;
$font-semi-dark: 400;
$font-light: 300;

//button-variables
$border-width: 2px;
$border-radius: 4px;
$button-font-size--xl: 20px;
$button-font-size--lg: 16px;

$limit: 200;
$i: 0;
@while $i <=$limit {
	.p-#{$i} {
		padding: 1px * $i !important;
	}

	.pt-#{$i} {
		padding-top: 1px * $i !important;
	}

	.pb-#{$i} {
		padding-bottom: 1px * $i !important;
	}

	.pr-#{$i} {
		padding-right: 1px * $i !important;
	}

	.pl-#{$i} {
		padding-left: 1px * $i !important;
	}

	.m-#{$i} {
		margin: 1px * $i !important;
	}

	.mt-#{$i} {
		margin-top: 1px * $i !important;
	}

	.mb-#{$i} {
		margin-bottom: 1px * $i !important;
	}

	.mr-#{$i} {
		margin-right: 1px * $i !important;
	}

	.ml-#{$i} {
		margin-left: 1px * $i !important;
	}

	.fs-#{$i} {
		font-size: 1px * $i !important;
	}

	.lh-#{$i} {
		line-height: 1px * $i !important;
	}

	$i: $i + 1;
}

.font-roboto {
	font-family: 'Roboto';
}

.font-roboto-slab {
	font-family: 'Roboto Slab';
}

.font-bold {
	font-weight: 700;
}

.font-semi-bold {
	font-weight: 600;
}

.font-dark {
	font-weight: 500;
}

.font-semi-dark {
	font-weight: 400;
}
.font-light {
	font-weight: 300;
}

.blue-text {
	color: #00aeef;
}

.black-text {
	color: #19202e;
	opacity: 1 !important;
}

.text-uppercase {
	text-transform: uppercase;
}
