#cookie-preferences {
	.modal {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000001;
		position: fixed;
		transition: all 0.3s ease-in-out;
		backdrop-filter: blur(5px);
		background-color: rgba(47, 47, 47, 0.4);
		align-items: center;
		justify-content: center;
		display: flex;

		.modal-container {
			width: fit-content;
			@media #{'only screen and (max-width : 767px)'} {
				margin-top: 20%;
			}
			.border {
				max-width: 794px;
				background-color: #ffffff;
				border-radius: 8px;
				box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
				overflow: hidden;
				position: relative;

				@media #{'only screen and (max-width : 767px)'} {
					overflow: scroll;
					scrollbar-color: #c8eaef #f3f3f3;
				}
				.fractal {
					position: absolute;
					left: 50%;
				}
				.logo {
					margin: 24px 0 0 32px;
				}
				.cancel-button {
					position: absolute;
					top: 24px;
					right: 24px;
					z-index: 2;

					cursor: pointer;
					&:hover {
						color: #3d4e70;
					}
				}
				.modal-contents {
					margin: 32px;
					.heading {
						margin: 8px 8px 0 8px;
					}
					.sub-heading {
						margin-bottom: 16px;
					}

					.message {
						margin: 8px 8px 32px 8px;
						.cookie-policy {
							font-weight: bolder;
						}
					}
					.cookie-item {
						border-top: 1px solid #d5d5d5;
						display: flex;
						justify-content: space-between;
						margin: 0 8px;
						.cookie-item-tag {
							white-space: nowrap;
							margin: 25px 0 0 0;
							@media #{'only screen and (max-width : 767px)'} {
								margin-bottom: 25px;
							}
						}
					}
				}
				.scroller {
					max-height: 452px;
					overflow-y: scroll;
					scrollbar-color: #c8eaef #f3f3f3;
					padding-right: 40px;
					margin: 32px 32px 8px 32px;

					@media #{'only screen and (min-width : 768px) and (max-width : 1024px)'} {
						padding-right: 16px;
					}
					@media #{'only screen and (max-width : 767px)'} {
						padding-right: 20px;
						height: 50%;
					}
					&::-webkit-scrollbar {
						width: 8px;
					}
					&::-webkit-scrollbar-track {
						background-color: #f3f3f3;
						border-radius: 8px;
					}
					&::-webkit-scrollbar-thumb {
						background: #d5d5d5;
						border-radius: 4px;
					}
				}
				.preference-buttons {
					border-top: 1px solid #d5d5d5;
					padding: 24px;
				}
			}
		}
	}
}
