.toggle-switch {
	@media #{'only screen and (max-width : 767px)'} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
.switch-label {
	margin: 25px 8px 0 0;
	display: inline-block;
	@media #{'only screen and (max-width : 767px)'} {
		margin: 25px 0 0 0;
	}
}
.switch-enabled {
	color: #19202e;
}

.switch-disabled {
	color: #979797;
}
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 22px;
	margin: 24px 0 0 0;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
	@media #{'only screen and (max-width : 767px)'} {
		margin: 8px 0 24px 0;
	}
}
.slider {
    all: unset;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 11px;
	&:before {
		position: absolute;
		content: '';
		height: 18px;
		width: 18px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}
	&:hover {
		box-shadow: 0px 0px 0px 3px rgba(61, 78, 112, 0.16);
	}
}
input {
	&:checked {
		+ {
			.slider {
				background-color: #3d4e70;
				&:before {
					-webkit-transform: translateX(18px);
					-ms-transform: translateX(18px);
					transform: translateX(18px);
				}
			}
		}
	}
	&:focus {
		+ {
			.slider {
				box-shadow: 0 0 1px #2196f3;
			}
		}
	}
}
